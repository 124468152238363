import { Injectable } from '@angular/core';
import {
  collection,
  collectionData,
  CollectionReference,
  doc,
  Firestore,
  limit,
  query,
  setDoc,
  where,
} from '@angular/fire/firestore';
import { Functions } from '@angular/fire/functions';
import { AuthQuery } from '@memberspot/admin/shared/data-access/auth';
import { HttpBackendService } from '@memberspot/frontend/shared/data-access/common';
import { IGetByUrl } from '@memberspot/frontend/shared/util/form';
import { BackendEndpoints, RecursivePartial } from '@memberspot/models';
import { MemberArea } from '@memberspot/shared/model/memberarea';
import {
  CreateSchoolDto,
  School,
  SchoolEndpoints,
  SchoolSettings,
  SelfSignupSettings,
  UpdateCommunitySettingsDto,
  UpdateSchoolDto,
} from '@memberspot/shared/model/school';
import { CollectionConfig, CollectionService } from 'akita-ng-fire';
import { Observable } from 'rxjs';
import { filter, switchMap, tap } from 'rxjs/operators';

import { SchoolsQuery } from './schools.query';
import { SchoolsState, SchoolsStore } from './schools.store';

@Injectable({ providedIn: 'root' })
@CollectionConfig({ path: 'schools' })
export class SchoolsService
  extends CollectionService<SchoolsState>
  implements IGetByUrl
{
  get schoolId() {
    return this.schoolsQuery.getActiveId() as string;
  }

  constructor(
    protected store: SchoolsStore,
    private authQuery: AuthQuery,
    private fns: Functions,
    private http: HttpBackendService,
    private schoolsQuery: SchoolsQuery,
    protected db: Firestore,
  ) {
    super(store);
  }

  setSchoolActive(schoolId: string | null) {
    this.store.setActive(schoolId);
  }

  validatedCname(url: string) {
    return this.http.generic(
      BackendEndpoints.SCHOOL.VALIDATED_CNAME({ schoolId: this.schoolId, url }),
    );
  }

  createSchool(dto: CreateSchoolDto) {
    return this.http.generic<School>(BackendEndpoints.SCHOOL.CREATE(dto));
  }

  deleteSchool(schoolId: string) {
    return this.http.generic(BackendEndpoints.SCHOOL.DELETE(schoolId));
  }

  updateSchool(schoolId: string, school: SchoolSettings) {
    return this.http.generic(
      BackendEndpoints.SCHOOL.UPDATE(schoolId, school as UpdateSchoolDto),
    );
  }

  updateSelfSignupSettings(schoolId: string, settings: SelfSignupSettings) {
    return this.http.generic(
      BackendEndpoints.SCHOOL.UPDATE_SELF_SIGNUP(schoolId, settings),
    );
  }

  updateCommunitySettings(settings: UpdateCommunitySettingsDto) {
    return this.http.generic(
      SchoolEndpoints.UPDATE_COMMUNITY_SETTINGS(settings),
    );
  }

  updateSchoolUrl(url: string, externalUrl?: boolean) {
    return this.http.generic(
      BackendEndpoints.SCHOOL.CHANGE_URL({
        schoolId: this.schoolId,
        url,
        externalUrl,
      }),
    );
  }

  syncSchoolsWithAccess() {
    this.store.setLoading(true);

    return this.authQuery.uid$.pipe(
      filter(Boolean),
      switchMap((uid) =>
        collectionData<School>(
          query(
            collection(this.db, 'schools') as CollectionReference<School>,
            where('admins', 'array-contains', uid),
          ),
          { idField: 'id' },
        ),
      ),
      tap((data) => this.store.upsertMany(data)),
      tap(() => this.store.setLoading(false)),
    );
  }

  getByUrl(url: string): Observable<MemberArea[]> {
    return collectionData<MemberArea>(
      query(
        collection(this.db, 'memberareas') as CollectionReference<MemberArea>,
        where('url', '==', url.toLocaleLowerCase()),
        limit(1),
      ),
      { idField: 'id' },
    );
  }

  setMerge(schoolId: string, upt: RecursivePartial<School>) {
    const ref = doc(this.db, `schools/${schoolId}`);

    return setDoc(ref, upt, { merge: true });
  }
}
